<template>
    <div class="nav-container">
        <nav class="navbar">
            <a href="/admin" class="logo" title="Logged in as admin">
                <MaltImg src="/rebranding2020/malt-logo/malt-yellow" width="125" height="36" alt="" />
                <span>Admin</span>
            </a>
            <ul class="nav-links">
                <li v-for="section in filteredSections" :key="section.name" class="nav-item">
                    <a
                        :href="section.link ? section.link : '#'"
                        :class="section.links.length > 0 ? 'with-caret' : ''"
                        role="menuitem"
                        :aria-haspopup="section.links.length > 0 ? 'true' : 'false'"
                    >
                        {{ $t(section.name as any) }}
                    </a>
                    <ul v-if="section.links" class="dropdown" aria-label="submenu">
                        <li v-for="link in section.links" :key="link.name" :class="{separator: link.separator}">
                            <template v-if="link.separator">
                                <hr />
                            </template>
                            <template v-else>
                                <NuxtLink v-if="link.link" :to="appLink(link.app, link.link)" :external="link.app !== app">
                                    {{ $t(link.name as any) }}
                                </NuxtLink>
                                <span v-if="!link.link" class="undone">
                                    {{ $t(link.name as any) }}
                                </span>
                                <span v-if="link.comment">
                                    <small>
                                        {{ link.comment }}
                                    </small>
                                </span>
                            </template>
                        </li>
                    </ul>
                </li>
                <li v-if="logout" class="nav-item">
                    <a href="#" role="menuitem" aria-haspopup="false" @click.prevent="logout">Logout</a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import type {PropType} from 'vue';
    import {onMounted, ref} from 'vue';
    import {useHasEnoughPriviledge} from '#imports';

    interface Link {
        name: string;
        comment?: string;
        app?: string;
        link: string;
        roles?: string[];
        separator?: true;
    }

    interface Section {
        name: string;
        link?: string;
        links: Link[];
    }

    const props = defineProps({
        /** the name of your app. It will be used internally by the component to know if it's an internal link or not */
        app: {type: String, required: true},
        /** the function to call when the user clicks on the logout link */
        logout: {
            type: Function as PropType<() => void>,
            default: () => {
                return () => {};
            },
        },
    });

    function appLink(app: string | undefined, link: string): string {
        if (app === undefined || app === props.app) {
            return link;
        }

        return `/${app}${link}`;
    }

    const filteredSections = ref<Section[]>([]);
    onMounted(async () => {
        const sections: Section[] = [
            {
                name: 'admin.menu.members',
                links: [
                    {
                        name: 'admin.menu.members.admin',
                        link: '/admin/manager',
                    },
                    {
                        name: 'admin.menu.members.sales.management',
                        link: '/crm/managers/account-managers',
                    },
                    {
                        name: 'admin.menu.members.finops.management',
                        link: '/admin/manager/payment-manager-attribution',
                    },
                    {
                        name: 'admin.menu.members.teams',
                        link: '/crm/managers/teams',
                    },
                ],
            },
            {
                name: 'admin.menu.finance',
                links: [
                    {
                        name: 'admin.menu.paper.invoices',
                        app: 'invoicing',
                        link: '/admin/paper-invoices',
                    },
                    {
                        name: 'admin.menu.financing.invoices',
                        link: '/project-financing-admin/invoices',
                        roles: ['ROLE_FINANCING'],
                    },
                    {
                        name: 'admin.menu.financing.billie',
                        link: '/project-financing-admin/billie',
                        roles: ['ROLE_BILLIE_FINANCING'],
                    },
                    {
                        name: 'admin.menu.financing.bnp',
                        link: '/project-financing-admin/bnp',
                        roles: ['ROLE_BNP_FINANCING'],
                    },
                    {
                        name: 'admin.menu.missions.integrity',
                        link: '/mission/admin/mission-integrity',
                    },
                    {
                        name: 'admin.menu.erp',
                        app: 'invoicing',
                        link: '/admin/erp',
                    },
                    {
                        separator: true,
                    } as Link,
                    {
                        name: 'admin.menu.download.invoices',
                        app: 'invoicing',
                        link: '/admin/export',
                        roles: ['ROLE_INVOICES'],
                    },
                    {
                        name: 'admin.menu.download.user.invoices',
                        app: 'invoicing',
                        link: '/admin/invoices-download',
                        roles: ['ROLE_INVOICES'],
                    },
                    {
                        name: 'admin.menu.download.paid-invoices',
                        app: 'invoicing',
                        link: '/admin/export-paid-invoices',
                        roles: ['ROLE_INVOICES'],
                    },
                    {
                        name: 'admin.menu.manual-invoices',
                        app: 'invoicing',
                        link: '/admin/manual-invoices',
                        roles: ['ROLE_INVOICES'],
                    },
                    {
                        separator: true,
                    } as Link,
                    {
                        name: 'admin.menu.servicelevel',
                        link: '/client-settings/admin/servicelevel',
                    },
                    {
                        name: 'admin.menu.pricing',
                        link: '/client-settings/admin/pricing',
                    },
                    {
                        name: 'admin.menu.freelancers.pf.collaborative',
                        app: 'taxes',
                        link: '/admin/freelancer-annual-dgfip-report',
                    },
                    {
                        name: 'admin.menu.freelancerfees',
                        link: '/admin/freelancer-fees',
                    },
                    {
                        name: 'admin.menu.client.fees.settings',
                        link: '/fees/admin/client-companies',
                    },
                    {
                        name: 'admin.menu.platform.synchronization',
                        app: 'invoicing',
                        link: '/admin/sync-billing-platform',
                    },
                    {
                        name: 'admin.menu.sync.payment.reminder',
                        app: 'payment-reminder',
                        link: '/admin/sync',
                        roles: ['ROLE_INVOICES'],
                    },
                    {
                        name: 'admin.menu.send.to.client.invoices.of.completed.projects',
                        app: 'invoicing',
                        link: '/admin/completed-project-emails',
                    },
                    {
                        name: 'admin.menu.payment.routing.admin',
                        link: '/payment-admin/routing-wallet/payIns',
                        roles: ['ROLE_PAYMENT'],
                    },
                ],
            },
            {
                name: 'admin.menu.content',
                links: [
                    {
                        name: 'admin.menu.content.rfp',
                        app: 'admin',
                        link: '/job-positions',
                    },
                    {
                        name: 'admin.menu.content.community',
                        link: '/cm/admin',
                    },
                    {
                        name: 'admin.menu.content.dashboard',
                        link: '/dashboard',
                        app: 'admin',
                    },
                    {
                        name: 'admin.menu.content.scatman',
                        link: '/scatman',
                    },
                    {
                        name: 'admin.menu.content.seo',
                        link: '/scatman/seo',
                    },
                    {
                        name: 'admin.menu.content.barometers',
                        link: '/barometer/admin',
                    },
                    {
                        name: 'admin.menu.content.nps',
                        app: 'admin',
                        link: '/nps',
                    },
                    {
                        name: 'admin.menu.content.sitemap',
                        link: '/sitemap/admin',
                    },
                    {
                        name: 'admin.menu.content.robots',
                        link: '/robots',
                        app: 'admin',
                    },
                ],
            },
            {
                name: 'Internationalization',
                links: [
                    {
                        name: 'admin.menu.countries',
                        app: 'admin',
                        link: '/country',
                    },
                    {
                        name: 'admin.menu.ops.lang',
                        app: 'admin',
                        link: '/l10n',
                    },
                ],
            },
            {
                name: 'Audit',
                links: [
                    {
                        name: 'admin.menu.ops.audit',
                        app: 'admin',
                        link: 'https://malt.cloud.looker.com/dashboards/475',
                    },
                    {
                        name: 'admin.menu.ops.usurpations',
                        app: 'admin',
                        link: 'https://malt.cloud.looker.com/dashboards/475',
                    },
                ],
            },
            {
                name: 'admin.menu.ops',
                links: [
                    {
                        name: 'admin.menu.ops.abtest',
                        app: 'admin',
                        link: '/abtest',
                    },
                    {
                        name: 'Accounts Deletion',
                        app: 'account',
                        link: '/admin/account-deletion',
                    },
                    {
                        name: 'admin.menu.caches',
                        app: 'admin',
                        link: '/caches',
                        roles: ['ROLE_TECHNICAL_SUPPORT'],
                    },
                    {
                        name: 'admin.menu.ops.queues',
                        link: '/queues',
                        app: 'admin',
                        roles: ['ROLE_TECHNICAL_SUPPORT'],
                    },
                    {
                        name: 'Feature Flags',
                        link: '/admin/ff4j/',
                    },
                    {
                        name: 'Feature Flags Audit',
                        link: '/admin/ff4j/audit',
                    },
                    {
                        name: 'admin.menu.ops.schedulers',
                        app: 'admin',
                        link: '/scheduler',
                        roles: ['ROLE_TECHNICAL_SUPPORT'],
                    },
                    {
                        name: 'admin.menu.ops.versions',
                        app: 'admin',
                        link: '/versions',
                    },
                    {
                        name: 'admin.menu.ops.search',
                        link: '/s/admin',
                    },

                    {
                        name: 'admin.menu.ops.ip',
                        app: 'admin',
                        link: '/abuse',
                    },
                    {
                        name: 'admin.menu.ops.parameters',
                        app: 'admin',
                        link: '/settings',
                    },
                    {
                        name: 'admin.menu.ops.session',
                        app: 'admin',
                        link: '/session-management',
                        roles: ['ROLE_SECURITY_TEAM'],
                    },
                    {
                        name: 'admin.menu.ops.loggers',
                        app: 'admin',
                        link: '/loggers',
                        roles: ['ROLE_TECHNICAL_SUPPORT'],
                    },
                    {
                        name: 'admin.menu.ops.sso',
                        app: 'sso',
                        link: '/organizations',
                    },
                    {
                        name: 'admin.menu.ops.globalidsearch',
                        app: 'admin',
                        link: '/globalidsearch',
                    },
                    {
                        name: 'admin.menu.ops.company.updates.audit.logs',
                        app: 'admin',
                        link: '/companies-audit-logs',
                    },
                ],
            },
            {
                name: 'admin.menu.crm',
                links: [
                    {
                        name: 'admin.menu.crm.home',
                        link: '/crm/app',
                    },
                    {
                        name: 'admin.menu.crm.rules',
                        app: 'rules-engine',
                        link: '/rules',
                    },
                    {
                        name: 'admin.menu.crm.scoring',
                        app: 'rules-engine',
                        link: '/scoring',
                    },
                    {
                        name: 'admin.menu.crm.tests',
                        app: 'rules-engine',
                        link: '/tests',
                    },
                    {
                        name: 'admin.menu.crm.variables',
                        app: 'rules-engine',
                        link: '/variables',
                    },
                    {
                        name: 'admin.menu.crm.mail.template',
                        app: 'rules-engine',
                        link: '/templates',
                    },

                    {
                        name: 'Email qualification',
                        link: '/admin/email-qualification',
                    },
                    {
                        name: 'admin.menu.crm.mail.blacklist',
                        link: '/crm/blacklists/email',
                    },
                    {
                        name: 'admin.menu.crm.auto.edr.blacklist',
                        link: '/signup-client/admin/auto-edr',
                    },
                    {
                        name: 'admin.menu.crm.words.blacklist',
                        link: '/crm/blacklists/expressions',
                    },
                    {
                        name: 'admin.menu.crm.documentation',
                        link: '/crm/documentation/root',
                    },
                    {
                        name: 'admin.menu.managers.attribution',
                        link: '/crm/managers/attribution',
                        roles: ['ROLE_ADMIN_MANAGER'],
                    },
                    {
                        name: 'admin.menu.managers.rc.groups',
                        link: '/rules-engine/sourcing-manager-groups',
                    },
                    {
                        name: 'admin.menu.managers.rc.leaves',
                        link: '/rules-engine/sourcing-manager-leaves',
                        roles: ['ROLE_ADMIN_MANAGER'],
                    },
                ],
            },
            {
                name: 'Mobile',
                links: [
                    {
                        name: 'admin.menu.mobile.version',
                        app: 'admin',
                        link: '/mobile',
                        roles: ['ROLE_MOBILE_ADMIN'],
                    },
                ],
            },
            {
                name: 'admin.menu.freelancers',
                links: [
                    {
                        name: 'admin.menu.freelance.super.malters',
                        app: 'admin',
                        link: '/loyalty',
                    },
                ],
            },
        ];

        for (const section of sections) {
            const links: Link[] = [];
            for (const link of section.links) {
                const isLinkVisible = await useHasEnoughPriviledge(link.roles || []);
                if (isLinkVisible) {
                    links.push(link);
                }
            }
            filteredSections.value.push({...section, links});
        }
    });
</script>

<style scoped lang="scss">
    .navbar {
        width: 100%;
        background-color: var(--joy-color-neutral-10);
        padding-left: 30px;
        padding-right: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            display: flex;
            flex-direction: column;

            span {
                color: var(--joy-color-quaternary-50);
                font-size: var(--joy-font-size-primary-200);
                text-align: end;
                margin-right: 20px;
            }
        }

        .nav-links {
            list-style: none;
            display: flex;
        }

        .nav-item a {
            display: block;
            padding: 10px 15px;
            text-decoration: none;
            color: var(--joy-color-neutral-50);
        }

        .nav-item:hover a {
            color: var(--joy-color-neutral-50);
        }

        .logo img {
            width: 175px;
            vertical-align: middle;
        }
    }

    .nav-container {
        box-shadow: var(--joy-core-elevation-1);
        font-size: var(--joy-font-size-primary-300);
    }

    .dropdown {
        background-color: var(--joy-color-neutral-10);
        z-index: 2;
        border: 1px solid var(--joy-color-neutral-30);
        border-radius: 6px;
        margin-top: 15px;
        overflow: hidden;

        > li {
            width: 100%;
            white-space: nowrap;
            padding: 5px;

            &:not(.separator):hover,
            &:not(.separator):focus-within {
                background-color: var(--joy-color-neutral-30);
                cursor: pointer;
            }

            &.separator {
                cursor: default;

                hr {
                    margin: 0 1rem;
                }
            }
        }
    }

    a {
        text-decoration: none;
        color: var(--joy-color-text-body);
        font-weight: var(--joy-font-weight-normal);
        font-family: var(--joy-font-family);

        &.with-caret {
            &:after {
                content: '\25BF';
                margin-left: 0.5em;
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding-left: 0;

        > li > ul {
            visibility: hidden;
            opacity: 0;
            min-width: 5rem;
            position: absolute;
            margin-top: 1rem;
            left: 0;
            display: none;
        }

        > li:hover > ul,
        > li:focus-within > ul,
        > li > ul:hover,
        > li > ul:focus {
            visibility: visible;
            opacity: 1;
            display: block;
        }
    }

    li {
        display: inline-block;
        padding: 1rem;
        position: relative;
        text-decoration: none;

        &:hover,
        &:focus-within {
            cursor: pointer;
        }
    }
</style>
<docs lang="md">
This component could be included in your application to add the admin menu. Typically it's used in a layout component
like this:

```vue
<template>
    <div>
        <AdminMenu app="admin" :logout="logout" />
        <slot />
    </div>
</template>

<script setup lang="ts">
    import {AdminMenu} from '#components';
    import {navigateTo, useUniversalPost} from '#imports';

    function logout() {
        useUniversalPost('/api/logout').then(() => {
            if (process.dev) {
                navigateTo('/signin');
            } else {
                window.location.href = '/signin';
            }
        });
    }
</script>
```
</docs>
