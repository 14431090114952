<template>
    <div>
        <ClientOnly>
            <div v-if="featureFlags?.length && featureFlags?.length > 0">
                <div v-if="!displayPanel" class="cta animated pulse">
                    <VJoyButton variant="admin" size="xsmall" @click.prevent="displayPanel = !displayPanel">FF</VJoyButton>
                </div>

                <div v-if="displayPanel" class="user-features-panel animated slideInRight">
                    <VJoyPanel no-margin>
                        <template #panel-title>Switch Feature Flag state</template>
                        <template #panel-title-action>
                            <VJoyButton circle icon="cross" variant="secondary" size="xsmall" @click.prevent="displayPanel = !displayPanel" />
                        </template>

                        <template #panel-body>
                            <VJoyPanelSection>
                                <template #panel-section-content>
                                    <VJoySelect v-model="selectModel" @update:modelValue="updateSelectedFeatureFlag">
                                        <option value="" disabled selected>Features...</option>
                                        <option
                                            v-for="featureFlag in featureFlags"
                                            :key="featureFlag.feature"
                                            :disabled="!featureFlag.isCanBeModifiedForUser"
                                            :value="featureFlag.feature"
                                        >
                                            {{ featureFlag.feature }} - {{ featureFlag.isEnabledForUser ? 'ON' : 'OFF' }}
                                        </option>
                                    </VJoySelect>
                                </template>
                            </VJoyPanelSection>
                        </template>

                        <template #panel-action>
                            <VJoyButton variant="primary" @click.prevent="switchState">Switch state</VJoyButton>
                        </template>
                    </VJoyPanel>
                </div>
            </div>
        </ClientOnly>
    </div>
</template>

<script setup lang="ts">
    /**
     * @deprecated use FooterAdminTools.vue instead
     */
    import {ref} from 'vue';
    import {useAsyncData, useFeatureFlag} from '#imports';
    import type {FeatureFlag} from '../composables/useFeatureFlag';
    import {VJoyButton, VJoyPanel, VJoySelect, VJoyPanelSection} from '@maltjoy/core-vue';

    const displayPanel = ref(false);
    const selectedFF = ref<FeatureFlag>();
    const selectModel = ref<FeatureFlag['feature']>('');

    const {data: featureFlags} = await useAsyncData('featureflags', () => initFeatureFlags());
    function initFeatureFlags() {
        return useFeatureFlag().fetchAllFeatureFlags();
    }

    function updateSelectedFeatureFlag(flag: string) {
        selectedFF.value = featureFlags.value?.find((ff) => ff.feature === flag);
    }

    function switchState() {
        useFeatureFlag()
            .switchStateFeatureFlag(selectedFF.value!.feature, !selectedFF.value!.isEnabledForUser)
            .then(() => {
                const featureFlag = featureFlags.value?.find((ff) => ff.feature === selectedFF.value!.feature);
                if (featureFlag) {
                    featureFlag.isEnabledForUser = !featureFlag.isEnabledForUser;
                }
            });
    }
</script>

<style lang="scss" scoped>
    .cta {
        transition: all 0.5s ease-out;
        position: fixed;
        z-index: 99;
        display: inline;
        right: -4px;
        bottom: 0;
        margin: 0;
        padding: 2px 6px 2px 2px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    .user-features-panel {
        transition: all 0.5s ease-out;
        position: fixed;
        z-index: 99;
        display: inline;
        right: -4px;
        bottom: 0;
        margin: 0;
        padding: 2px 0 2px 2px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: var(--joy-color-quaternary-30);
    }
    .animated {
        animation-duration: 0.5s;
        animation-fill-mode: both;
    }

    .pulse {
        animation-duration: 4s;
        animation-name: pulse;
        animation-iteration-count: infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.9);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.9);
        }
    }

    .slideInRight {
        animation-name: slideInRight;
    }

    @keyframes slideInRight {
        0% {
            transform: translate3d(100%, 0, 0);
            visibility: visible;
        }

        100% {
            transform: translate3d(0, 0, 0);
        }
    }

    .user-features-panel.open {
        height: auto;
    }

    .user-features-panel select {
        max-width: 100%;
        color: black;
    }

    .user-features-panel a {
        color: white;
    }

    #userFeaturesPanelToggle {
        text-decoration: none;
    }

    #userFeaturesPanelContent {
        display: none;
        min-width: 500px;
    }
</style>
