<template>
    <div class="joy-wrapper">
        <SudosuFrame />

        <AdminMenu app="" :logout="logout" />

        <slot />

        <FeatureFlagWidget />
    </div>
</template>
<script setup lang="ts">
    import {AdminMenu, SudosuFrame, FeatureFlagWidget} from '#components';
    import {navigateTo, useUniversalPost} from '#imports';

    function logout() {
        useUniversalPost('/api/logout').then(() => {
            if (process.dev) {
                navigateTo('/signin');
            } else {
                window.location.href = '/signin';
            }
        });
    }
</script>
